import React from 'react';
import { BrowserRouter as Router, Route, Routes, NavLink, useLocation } from 'react-router-dom';
import './App.css';
import HomeScreen from './Screens/HomeScreen';
import MapScreen from './Screens/MapScreen';
import ProfileScreen from './Screens/ProfileScreen';
import { Home, Map, User, Menu } from 'lucide-react';
import BusinessScreen from './Screens/BusinessScreen';
import AuthScreen from './Screens/AuthScreen';
import ExplorePage from './Screens/ExploreScreen';
import NavScreen from './Screens/NavScreen';

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

function AppContent() {
  const location = useLocation();
  const showNavBar = location.pathname !== '/auth';

  return (
    <div className="App">
      <div className="content">
        <Routes>
          <Route path="/" element={<HomeScreen />} />
          <Route path="/map" element={<MapScreen />} />
          <Route path="/profile" element={<ProfileScreen />} />
          <Route path="/business" element={<BusinessScreen />} />
          <Route path="/auth" element={<AuthScreen />} />
          <Route path="/explore" element={<ExplorePage />} />
          <Route path="/nav" element={<NavScreen />} />
        </Routes>
      </div>
      {showNavBar && (
        <nav>
          <ul>
            <li>
              <NavLink to="/" className={({ isActive }) => isActive ? "active" : ""}>
                <Home className="w-6 h-6 mx-auto" />
                {/* <span className="text-xs">Home</span> */}
              </NavLink>
            </li>
            <li>
              <NavLink to="/map" className={({ isActive }) => isActive ? "active" : ""}>
                <Map className="w-6 h-6 mx-auto" />
                {/* <span className="text-xs">Map</span> */}
              </NavLink>
            </li>
            <li>
              <NavLink to="/profile" className={({ isActive }) => isActive ? "active" : ""}>
                <User className="w-6 h-6 mx-auto" />
                {/* <span className="text-xs">Profile</span> */}
              </NavLink>
            </li>
            <li>
              <NavLink to="/nav" className={({ isActive }) => isActive ? "active" : ""}>
                <Menu className="w-6 h-6 mx-auto" />
                {/* <span className="text-xs">Nav</span> */}
              </NavLink>
            </li>
          </ul>
        </nav>
      )}
    </div>
  );
}

export default App;