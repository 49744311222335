import React from 'react'
import { useNavigate } from 'react-router-dom'


export default function NavScreen() {
  const navigate = useNavigate()

  return (
    <div className="flex flex-col items-center h-screen justify-center bg-black">
        <h1 className="text-2xl font-bold mb-4">Nav Screen</h1>
      <div className="space-y-4 w-full max-w-xs">
        <button className="w-full bg-blue-500 text-white py-2 px-4 rounded-lg shadow hover:bg-blue-600 transition duration-300" 
        onClick={() => navigate('/explore')}
        >
          Explore v2
        </button>
        <button className="w-full bg-green-500 text-white py-2 px-4 rounded-lg shadow hover:bg-green-600 transition duration-300">
          Home v2 (swipe)
        </button>
        <button className="w-full bg-yellow-500 text-white py-2 px-4 rounded-lg shadow hover:bg-yellow-600 transition duration-300">
          Home v3 (vercel)
        </button>
        <button className="w-full bg-red-500 text-white py-2 px-4 rounded-lg shadow hover:bg-red-600 transition duration-300">
          Auth
        </button>
      </div>
    </div>
  )
}
