import React, { useRef, useEffect } from 'react'
import VideoPost from './VideoPost'

export default function VideoFeed({ posts, currentIndex, setCurrentIndex, bookmarkedPosts, likedPosts, setLikedPosts, onBookmarkClick }) {
  const containerRef = useRef(null)
  const videoRefs = useRef([])

  useEffect(() => {
    const container = containerRef.current
    if (!container) return

    const handleScroll = () => {
      const scrollTop = container.scrollTop
      const postHeight = container.clientHeight
      const index = Math.round(scrollTop / postHeight)
      setCurrentIndex(index)
    }

    container.addEventListener('scroll', handleScroll)
    return () => container.removeEventListener('scroll', handleScroll)
  }, [setCurrentIndex])

  useEffect(() => {
    videoRefs.current.forEach((video, index) => {
      if (index === currentIndex && video) {
        video.play().catch(error => console.error("Error playing video:", error))
      } else if (video) {
        video.pause()
        video.currentTime = 0
      }
    })
  }, [currentIndex])

  return (
    <div ref={containerRef} className="h-full overflow-y-scroll snap-y snap-mandatory scroll-smooth">
      {posts.map((post, index) => (
        <VideoPost
          key={post.id}
          post={post}
          index={index}
          videoRef={el => videoRefs.current[index] = el}
          isBookmarked={!!bookmarkedPosts[index]}
          isLiked={likedPosts[index]}
          onLike={() => setLikedPosts(prev => ({...prev, [index]: !prev[index]}))}
          onBookmark={onBookmarkClick}
        />
      ))}
    </div>
  )
}
