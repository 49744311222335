// Import necessary dependencies
"use client"

import { useState, Fragment } from "react"
import { Listbox, Switch, Transition } from '@headlessui/react'
import { Instagram, Twitter } from "lucide-react"

// Main component for authentication screen
export default function Component() {
  // State to toggle between login and signup
  const [isLogin, setIsLogin] = useState(true)

  return (
    // Main container with gradient background
    <div className="flex px-4 items-center justify-center min-h-screen bg-gradient-to-br from-purple-400 via-pink-500 to-red-500">
      {/* White card container */}
      <div className="w-full max-w-md p-8 space-y-8 bg-white rounded-3xl shadow-xl">
        {/* Header section */}
        <div className="text-center">
          <h2 className="text-3xl font-bold text-gray-900">
            {isLogin ? "Welcome back! 👋" : "Join the squad! 🎉"}
          </h2>
          <p className="mt-2 text-sm text-gray-600">
            {isLogin ? "We missed you!" : "It's gonna be lit!"}
          </p>
        </div>
        {/* Authentication form */}
        <form className="mt-8 space-y-6">
          <div className="space-y-4">
            {/* Email input field */}
            <div>
              <label htmlFor="email" className="sr-only">
                Email address
              </label>
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="w-full px-4 py-3 rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                placeholder="Drop your email here"
              />
            </div>
            {/* Password input field */}
            <div>
              <label htmlFor="password" className="sr-only">
                Password
              </label>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className="w-full px-4 py-3 rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                placeholder="Super secret password"
              />
            </div>
            {/* Confirm password field (only shown for signup) */}
            {!isLogin && (
              <div>
                <label htmlFor="confirmPassword" className="sr-only">
                  Confirm Password
                </label>
                <input
                  id="confirmPassword"
                  name="confirmPassword"
                  type="password"
                  autoComplete="new-password"
                  required
                  className="w-full px-4 py-3 rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                  placeholder="Confirm that secret password"
                />
              </div>
            )}
          </div>

          {/* Submit button */}
          <div>
            <button
              type="submit"
              className="w-full px-4 py-3 text-white bg-gradient-to-r from-purple-500 to-pink-500 rounded-full hover:from-purple-600 hover:to-pink-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
            >
              {isLogin ? "Let's go! 🚀" : "Sign me up! 🎈"}
            </button>
          </div>
        </form>

        {/* Social media login buttons */}
        <div className="flex items-center justify-center space-x-4">
          {/* Instagram login button */}
          <button
            className="p-2 rounded-full bg-pink-100 hover:bg-pink-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
          >
            <Instagram className="w-5 h-5 text-pink-500" />
            <span className="sr-only">Sign in with Instagram</span>
          </button>
          {/* Twitter login button */}
          <button
            className="p-2 rounded-full bg-blue-100 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            <Twitter className="w-5 h-5 text-blue-500" />
            <span className="sr-only">Sign in with Twitter</span>
          </button>
        </div>

        {/* Toggle switch for login/signup */}
        <div className="flex items-center justify-center">
          <Switch
            checked={!isLogin}
            onChange={() => setIsLogin(!isLogin)}
            className={`${
              !isLogin ? 'bg-purple-600' : 'bg-gray-200'
            } relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2`}
          >
            <span className="sr-only">Toggle login/signup</span>
            <span
              className={`${
                !isLogin ? 'translate-x-6' : 'translate-x-1'
              } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
            />
          </Switch>
          {/* Text to indicate current mode (login/signup) */}
          <span className="ml-3 text-sm font-medium text-gray-900">
            {isLogin ? "New here? Sign up" : "Already in? Log in"}
          </span>
        </div>
      </div>
    </div>
  )
}