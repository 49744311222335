import { useState } from 'react'

const initialPosts = [
    { id: 1, username: "Camp Monk", description: "Enjoying nature!", likes: 1000, videoUrl: "https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/360/Big_Buck_Bunny_360_10s_1MB.mp4" },
    { id: 2, username: "user2", description: "New recipe!", likes: 2000, videoUrl: "https://test-videos.co.uk/vids/jellyfish/mp4/h264/360/Jellyfish_360_10s_1MB.mp4" },
    { id: 3, username: "user3", description: "Morning routine", likes: 3000, videoUrl: "https://test-videos.co.uk/vids/sintel/mp4/h264/360/Sintel_360_10s_1MB.mp4" },
    { id: 4, username: "Kodaikanal Lake", description: "Beautiful sunset", likes: 4000, videoUrl: "https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/360/Big_Buck_Bunny_360_10s_1MB.mp4" },
    { id: 5, username: "user5", description: "New song cover!", likes: 5000, videoUrl: "https://test-videos.co.uk/vids/jellyfish/mp4/h264/360/Jellyfish_360_10s_1MB.mp4" },
    { id: 6, username: "user6", description: "My latest painting", likes: 6000, videoUrl: "https://test-videos.co.uk/vids/sintel/mp4/h264/360/Sintel_360_10s_1MB.mp4" },
    { id: 7, username: "MountainExplorer", description: "Scaling new heights!", likes: 7500, videoUrl: "https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/360/Big_Buck_Bunny_360_10s_1MB.mp4" },
    { id: 8, username: "CityNomad", description: "Urban adventures", likes: 8200, videoUrl: "https://test-videos.co.uk/vids/jellyfish/mp4/h264/360/Jellyfish_360_10s_1MB.mp4" },
    { id: 9, username: "FoodieFinds", description: "Trying local delicacies", likes: 9100, videoUrl: "https://test-videos.co.uk/vids/sintel/mp4/h264/360/Sintel_360_10s_1MB.mp4" },
    { id: 10, username: "Munnar Tea Gardens", description: "Lush green paradise", likes: 10000, videoUrl: "https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/360/Big_Buck_Bunny_360_10s_1MB.mp4" },
    { id: 11, username: "BeachBum", description: "Surfing the waves", likes: 11300, videoUrl: "https://test-videos.co.uk/vids/jellyfish/mp4/h264/360/Jellyfish_360_10s_1MB.mp4" },
    { id: 12, username: "YogaJourney", description: "Finding inner peace", likes: 12600, videoUrl: "https://test-videos.co.uk/vids/sintel/mp4/h264/360/Sintel_360_10s_1MB.mp4" },
    { id: 13, username: "WildlifeWatcher", description: "Spotted a rare bird!", likes: 13900, videoUrl: "https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/360/Big_Buck_Bunny_360_10s_1MB.mp4" },
    { id: 14, username: "TechGuru", description: "Latest gadget review", likes: 14200, videoUrl: "https://test-videos.co.uk/vids/jellyfish/mp4/h264/360/Jellyfish_360_10s_1MB.mp4" },
    { id: 15, username: "ArtisticSoul", description: "Street art discoveries", likes: 15500, videoUrl: "https://test-videos.co.uk/vids/sintel/mp4/h264/360/Sintel_360_10s_1MB.mp4" },
    { id: 16, username: "Ooty Hill Station", description: "Misty mountain views", likes: 16800, videoUrl: "https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/360/Big_Buck_Bunny_360_10s_1MB.mp4" },
    { id: 17, username: "BookWorm", description: "My reading nook", likes: 17100, videoUrl: "https://test-videos.co.uk/vids/jellyfish/mp4/h264/360/Jellyfish_360_10s_1MB.mp4" },
    { id: 18, username: "GreenThumb", description: "Garden makeover", likes: 18400, videoUrl: "https://test-videos.co.uk/vids/sintel/mp4/h264/360/Sintel_360_10s_1MB.mp4" },
    { id: 19, username: "FitnessFreak", description: "New personal best!", likes: 19700, videoUrl: "https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/360/Big_Buck_Bunny_360_10s_1MB.mp4" },
    { id: 20, username: "MusicMaestro", description: "Composing a new tune", likes: 20000, videoUrl: "https://test-videos.co.uk/vids/jellyfish/mp4/h264/360/Jellyfish_360_10s_1MB.mp4" },
    { id: 21, username: "TravelBug", description: "Hidden gems of India", likes: 21300, videoUrl: "https://test-videos.co.uk/vids/sintel/mp4/h264/360/Sintel_360_10s_1MB.mp4" },
    { id: 22, username: "Pondicherry Beach", description: "Serene coastal vibes", likes: 22600, videoUrl: "https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/360/Big_Buck_Bunny_360_10s_1MB.mp4" },
    { id: 23, username: "CoffeeConnoisseur", description: "Perfect brew", likes: 23900, videoUrl: "https://test-videos.co.uk/vids/jellyfish/mp4/h264/360/Jellyfish_360_10s_1MB.mp4" },
    { id: 24, username: "DIYenthusiast", description: "Upcycling project", likes: 24200, videoUrl: "https://test-videos.co.uk/vids/sintel/mp4/h264/360/Sintel_360_10s_1MB.mp4" },
    { id: 25, username: "NightOwl", description: "City lights", likes: 25500, videoUrl: "https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/360/Big_Buck_Bunny_360_10s_1MB.mp4" },
    { id: 26, username: "PetLover", description: "Adopted a new friend", likes: 26800, videoUrl: "https://test-videos.co.uk/vids/jellyfish/mp4/h264/360/Jellyfish_360_10s_1MB.mp4" },
  ];
  

export function usePosts() {
  const [posts] = useState(initialPosts)
  const [bookmarkedPosts, setBookmarkedPosts] = useState({})
  const [likedPosts, setLikedPosts] = useState({})

  return { posts, bookmarkedPosts, setBookmarkedPosts, likedPosts, setLikedPosts }
}