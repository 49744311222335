import React from 'react'
import { Search, Plus } from 'lucide-react'
import { useNavigate } from 'react-router-dom'

export default function Header() {
  const navigate = useNavigate()

  return (
    <div className="flex justify-between items-center p-4 bg-black bg-opacity-75 backdrop-blur-md z-50 fixed top-0 left-0 right-0">
      <h1 className="text-xl font-bold">🤙 Scenes</h1>
      <div className="flex items-center space-x-6">
        <Search className="w-6 h-6" onClick={() => navigate('/map')}/>
        <Plus className="w-6 h-6" onClick={() => navigate('/auth')} />
      </div>
    </div>
  )
}