// File: HomeScreen.js
import React, { useState, useEffect } from 'react'
import Header from '../Components/Header'
import VideoFeed from '../Components/VideoFeed'
import BottomSheet from '../Components/BottomSheet'
import { usePosts } from '../Hooks/usePosts'
import { useWindowSize } from '../Hooks/useWindowSize'


export default function HomeScreen() {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false)
  const { height: windowHeight } = useWindowSize()
  const { posts, bookmarkedPosts, setBookmarkedPosts, likedPosts, setLikedPosts } = usePosts()

  return (
    <div className="h-[82vh] w-full bg-black text-white overflow-hidden flex flex-col">
      <Header/>
      <VideoFeed
        posts={posts}
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
        bookmarkedPosts={bookmarkedPosts}
        likedPosts={likedPosts}
        setLikedPosts={setLikedPosts}
        onBookmarkClick={() => setIsBottomSheetOpen(true)}
      />
      <BottomSheet
        isOpen={isBottomSheetOpen}
        onClose={() => setIsBottomSheetOpen(false)}
        onSave={(folder) => {
          setBookmarkedPosts(prev => ({...prev, [currentIndex]: folder}))
          setIsBottomSheetOpen(false)
        }}
      />
      {/* <div className="h-[5rem]"></div> */}
    </div>
  )
}
