import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Heart } from 'lucide-react';

const LikeButton = ({ index, initialLiked = false }) => {
  const [liked, setLiked] = useState(initialLiked);

  const handleLikeClick = () => {
    setLiked(!liked);
  };

  return (
    <motion.button 
      className="p-2 bg-gray-800 bg-opacity-50 rounded-full transition-colors duration-300 hover:bg-opacity-70"
      onClick={() => handleLikeClick()}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
      animate={{
        rotate: liked ? [0, -15, 15, -15, 0] : 0,
      }}
      transition={{
        duration: 0.5,
        ease: "easeInOut",
      }}
    >
      <motion.div
        animate={{ scale: liked ? [1, 1.2, 1] : 1 }}
        transition={{ duration: 0.3 }}
      >
        <Heart 
          className={`w-6 h-6 transition-colors duration-300 ${
            liked ? 'text-red-500 fill-current' : 'text-gray-300'
          }`} 
        />
      </motion.div>
    </motion.button>
  );
};

export default LikeButton;