import React, { useState, useEffect } from 'react';
import { Search, MapPin, Navigation } from 'lucide-react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

const ExploreScreen = () => {
  const [searchVisible, setSearchVisible] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const timer = setTimeout(() => {
      setSearchVisible(true);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);
  

  return (
    <div className="min-h-screen bg-black text-white p-4 pb-32 overflow-hidden">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Hi Slava!</h1>
        <div className="w-8 h-8 bg-gray-600 rounded-full"></div>
      </div>

      {/* map */}

      <motion.div
        initial={{ y: -20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.2 }}
        className="mb-6 cursor-pointer"
        onClick={() => navigate('/map')}
      >
        <div className="bg-gray-800 rounded-lg p-4 flex items-center justify-center h-48">
          <h2 className="text-3xl font-bold text-white">
            Explore the Map
          </h2>
        </div>
      </motion.div>

      <div className="relative mb-6">
        <input
          type="text"
          placeholder="Search destinations..."
          className="w-full bg-gray-800 text-white px-4 py-2 pl-10 pr-4 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-600"
        />
        <Search size={16} className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
      </div>

      <motion.div
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.4 }}
        className="flex space-x-2 mb-6"
      >
        <button className="bg-gray-800 text-white px-4 py-2 rounded-full">
          <Navigation size={16} className="inline mr-2" />
          Nearby
        </button>
        <button className="bg-gray-800 text-white px-4 py-2 rounded-full">
          <MapPin size={16} className="inline mr-2" />
          Home
        </button>
      </motion.div>

      <motion.div
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.6 }}
        className="grid grid-cols-2 gap-4"
      >
        <div className="bg-gray-800 rounded-lg aspect-[2/3] p-4 flex flex-col justify-between">
          <div>
            <h3 className="text-xl font-semibold mb-2">Dubai</h3>
            <p className="text-gray-400">United Arab Emirates</p>
          </div>
          <img src="/api/placeholder/200/300" alt="Dubai Skyline" className="w-full h-48 object-cover rounded-lg" />
        </div>
        <div className="grid grid-rows-2 gap-4">
          <div className="bg-gray-800 rounded-lg p-4 flex items-center justify-between">
            <div>
              <h3 className="text-lg font-semibold">SFO → DUB</h3>
              <p className="text-sm text-gray-400">21 Jul, 2023</p>
            </div>
            <div className="bg-blue-600 text-white px-3 py-1 rounded-full text-sm">$854</div>
          </div>
          <div className="bg-gray-800 rounded-lg p-4">
            <h3 className="text-lg font-semibold mb-2">39°C</h3>
            <p className="text-sm text-gray-400">Sunny</p>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default ExploreScreen;