import React, { useState, useMemo, useCallback, useEffect, useRef } from 'react';
import Map, { Source, Layer, Marker } from 'react-map-gl';
import { Search } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import places from '../Data/places.json';

const MAPBOX_TOKEN = 'pk.eyJ1Ijoic3IxazRyIiwiYSI6ImNtMHM5aWN0aDBhZ3cycXI2MG9ndDY2OWoifQ.Y0TLOM0RctYGHt6qwB3OWw';

export default function MapScreen() {
  const navigate = useNavigate();
  const [viewState, setViewState] = useState({
    latitude: 12.9719,
    longitude: 77.6412,
    zoom: 14
  });
  const [activeFilter, setActiveFilter] = useState('All');
  const [isLocationModalOpen, setIsLocationModalOpen] = useState(false);
  const mapRef = useRef(null);

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setViewState({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            zoom: 14
          });
        },
        (error) => {
          console.error("Error getting user location:", error);
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0
        }
      );
    } else {
      console.log("Geolocation is not available in this browser.");
    }
  }, []);

  const filters = useMemo(() => [
    { id: 1, name: 'All', icon: '🌍' },
    { id: 2, name: 'Restaurants', icon: '🍽️' },
    { id: 3, name: 'Cafes', icon: '☕' },
    { id: 4, name: 'Shopping', icon: '🛍️' },
    { id: 5, name: 'Nightlife', icon: '🌃' },
    { id: 6, name: 'Parks', icon: '🏞️' },
    { id: 7, name: 'Outdoors', icon: '🏞️' },
    { id: 8, name: 'Local Attractions', icon: '🏛️' },
    { id: 9, name: 'Sports', icon: '🏟️' },
  ], []);

  const handleFilterClick = useCallback((filterName) => {
    setActiveFilter(filterName);
  }, []);

  const filteredPlaces = useMemo(() => {
    if (!activeFilter || activeFilter === 'All') return places;
    return places.filter(place => place.type === activeFilter);
  }, [activeFilter]);

  const geojson = useMemo(() => ({
    type: 'FeatureCollection',
    features: filteredPlaces.map(place => ({
      type: 'Feature',
      properties: {
        id: place.id,
        icon: place.icon,
        name: place.name,
        type: place.type
      },
      geometry: {
        type: 'Point',
        coordinates: [place.lng, place.lat]
      }
    }))
  }), [filteredPlaces]);

  const layerStyle = {
    id: 'clusters',
    type: 'circle',
    source: 'places',
    filter: ['has', 'point_count'],
    paint: {
      'circle-color': [
        'step',
        ['get', 'point_count'],
        '#51bbd6',
        100,
        '#f1f075',
        750,
        '#f28cb1'
      ],
      'circle-radius': [
        'step',
        ['get', 'point_count'],
        20,
        100,
        30,
        750,
        40
      ]
    }
  };

  const clusterCountLayer = {
    id: 'cluster-count',
    type: 'symbol',
    source: 'places',
    filter: ['has', 'point_count'],
    layout: {
      'text-field': '{point_count_abbreviated}',
      'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
      'text-size': 12
    }
  };

  const unclusteredPointLayer = {
    id: 'unclustered-point',
    type: 'circle',
    source: 'places',
    filter: ['!', ['has', 'point_count']],
    paint: {
      'circle-color': '#11b4da',
      'circle-radius': 4,
      'circle-stroke-width': 1,
      'circle-stroke-color': '#fff'
    }
  };

  const onClick = useCallback((event) => {
    const feature = event.features && event.features[0];
    if (feature && feature.properties.cluster) {
      const clusterId = feature.properties.cluster_id;
      const mapboxSource = mapRef.current.getSource('places');
      
      mapboxSource.getClusterExpansionZoom(clusterId, (err, zoom) => {
        if (err) return;

        setViewState({
          ...viewState,
          longitude: feature.geometry.coordinates[0],
          latitude: feature.geometry.coordinates[1],
          zoom,
          transitionDuration: 500
        });
      });
    } else if (feature && !feature.properties.cluster) {
      navigate('/business', { state: { place: feature.properties } });
    }
  }, [navigate, viewState]);

  const renderMarkers = useCallback(() => {
    if (!mapRef.current) return null;
    const map = mapRef.current.getMap();
    const bounds = map.getBounds();

    return filteredPlaces.filter(place => {
      const [lng, lat] = [place.lng, place.lat];
      return bounds.contains([lng, lat]);
    }).map(place => (
      <Marker
        key={place.id}
        longitude={place.lng}
        latitude={place.lat}
        anchor="bottom"
        onClick={() => navigate('/business', { state: { place } })}
      >
        <div className="bg-gray-700 p-2 rounded-full">
          <div style={{ color: 'white', fontSize: '14px' }}>
            {place.icon} {place.name}
          </div>
        </div>
      </Marker>
    ));
  }, [filteredPlaces, navigate]);

  const FilterButtons = useCallback(({ filters, onFilterClick, activeFilter }) => {
    return (
      <div className="flex overflow-x-auto pb-2 mb-4 scrollbar-hide scroll-smooth snap-x snap-mandatory">
        {filters.map((filter) => (
          <button
            key={filter.id}
            className={`px-4 py-2 mr-2 text-sm bg-black bg-opacity-50 text-white rounded-full whitespace-nowrap ${activeFilter === filter.name ? 'bg-red-500' : ''} ${activeFilter === 'All' && filter.name === 'All' ? 'bg-red-500' : ''}`}
            onClick={() => onFilterClick(filter.name)}
          >
            {filter.icon} {filter.name}
          </button>
        ))}
      </div>
    );
  }, []);

  return (
    <div className="fixed inset-0 flex flex-col">
      <div className="absolute top-0 left-0 right-0 bg-gradient-to-b from-black to-transparent z-10 p-4 pt-4 flex items-center justify-between">
        <h1 
          className="text-white text-l font-bold cursor-pointer hover:text-gray-300 transition-colors duration-300"
          onClick={() => setIsLocationModalOpen(true)}
        >
          📍 Indira Nagar
        </h1>
      </div>

      <div className="absolute top-16 left-4 right-4 z-20">
        <div className="relative mb-2">
          <input
            type="text"
            placeholder="Search locations..."
            className="w-full py-2 pl-10 pr-4 text-white bg-black bg-opacity-50 backdrop-blur-md rounded-full focus:outline-none focus:ring-1 focus:ring-gray-600 shadow-lg"
          />
          <Search className="absolute left-3 top-2.5 text-gray-400" size={20} />
        </div>
        <FilterButtons filters={filters} onFilterClick={handleFilterClick} activeFilter={activeFilter} />
      </div>

      {isLocationModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
          <div className="bg-gray-900 rounded-lg p-6 w-full max-w-md">
            <h2 className="text-2xl font-bold mb-4 text-white">Select Location</h2>
            <input
              type="text"
              placeholder="Search for a location..."
              className="w-full p-2 bg-gray-800 text-white border border-gray-700 rounded mb-4"
            />
            <div className="flex justify-end">
              <button
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors duration-300"
                onClick={() => setIsLocationModalOpen(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      <div className='flex-grow relative'>
        <Map
          ref={mapRef}
          {...viewState}
          onMove={evt => setViewState(evt.viewState)}
          style={{position: 'absolute', width: '100%', height: '100%'}}
          mapStyle="mapbox://styles/mapbox/dark-v11"
          mapboxAccessToken={MAPBOX_TOKEN}
          interactiveLayerIds={['clusters', 'unclustered-point']}
          onClick={onClick}
        >
          <Source
            id="places"
            type="geojson"
            data={geojson}
            cluster={true}
            clusterMaxZoom={14}
            clusterRadius={50}
          >
            <Layer {...layerStyle} />
            <Layer {...clusterCountLayer} />
            <Layer {...unclusteredPointLayer} />
          </Source>
          {viewState.zoom >= 14 && renderMarkers()}
        </Map>
      </div>
    </div>
  );
}