import React, { useState } from 'react'
import { Send, Bookmark } from 'lucide-react'
import LikeButton from './LikeButton'

export default function VideoPost({ post, index, videoRef, isBookmarked, isLiked, onLike, onBookmark }) {
  const [hasError, setHasError] = useState(false)

  const handleVideoError = () => {
    console.error(`Error loading video for post ${index}`)
    setHasError(true)
  }

  const handleVideoClick = () => {
    if (videoRef.paused) {
      videoRef.play().catch(error => console.error("Error playing video:", error))
    } else {
      // videoRef.pause()
    }
  }

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: post.description,
          text: `Check out this video from ${post.username}!`,
          url: window.location.href,
        })
      } catch (error) {
        console.error('Error sharing:', error)
      }
    } else {
      console.log('Web Share API not supported')
      // Fallback for desktop or unsupported browsers
      // You could implement a custom share dialog here
    }
  }

  return (
    <div className="h-full w-full snap-start snap-mandatory flex flex-col justify-between relative max-w-screen-sm mx-auto">
      {!hasError && (
        <video
          ref={videoRef}
          src={post.videoUrl}
          className="absolute inset-0 w-full h-full object-cover rounded-[8vw] z-0"
          loop
          muted
          playsInline
          onError={handleVideoError}
          onClick={handleVideoClick}
        />
      )}
      <div className="z-10 absolute top-0 left-0 right-0 p-2 rounded-lg self-start text-left mt-10 ml-4 mr-8">
        <p className="text-2xl font-bold ">{post.description} at {post.username}</p>
      </div>
      <div className="z-10 absolute bottom-0 left-0 right-0 flex justify-between items-end w-full mb-4 px-4">
        <div className="flex-1"></div>
        <div className="flex flex-col items-center gap-4 mb-24">
          <LikeButton isLiked={isLiked} onLike={onLike} />
          <button className="p-2 bg-gray-800 bg-opacity-50 rounded-full mb-8 hover:bg-gray-700" onClick={onBookmark}>
            <Bookmark className={`w-6 h-6 ${isBookmarked ? 'text-blue-500 fill-current' : ''}`} />
          </button>
          <button className="p-2 bg-gray-800 bg-opacity-50 rounded-full hover:bg-gray-700" onClick={handleShare}>
            <Send className="w-6 h-6" />
          </button>
        </div>
      </div>
    </div>
  )
}
