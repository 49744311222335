import React, { useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { X, Plus } from 'lucide-react'

export default function BottomSheet({ isOpen, onClose, onSave }) {
  const [savedFolders, setSavedFolders] = useState(['Favorites', 'Watch Later'])
  const [newFolderName, setNewFolderName] = useState('')

  const handleCreateFolder = () => {
    if (newFolderName.trim() !== '') {
      setSavedFolders([...savedFolders, newFolderName.trim()])
      setNewFolderName('')
    }
  }

  const handleFolderSelect = (folder) => {
    onSave(folder)
  }

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ y: "100%" }}
          animate={{ y: "20%" }}
          exit={{ y: "100%" }}
          transition={{ type: "spring", damping: 25, stiffness: 400 }}
          className="fixed inset-x-0 bottom-0 bg-black rounded-t-3xl z-50 p-6 flex flex-col"
          style={{ height: "70vh" }}
        >
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-bold">Save</h2>
            <button onClick={onClose}>
              <X className="w-6 h-6" />
            </button>
          </div>
          <div className="mb-4">
            <div className="flex items-center bg-gray-800 rounded-lg overflow-hidden">
              <input
                type="text"
                value={newFolderName}
                onChange={(e) => setNewFolderName(e.target.value)}
                placeholder="New folder name"
                className="flex-1 p-3 bg-transparent text-white outline-none"
              />
              <button
                onClick={handleCreateFolder}
                className="bg-blue-500 text-white p-3 px-4 hover:bg-blue-600 transition-colors"
              >
                <Plus className="w-5 h-5" />
              </button>
            </div>
          </div>
          <div className="flex-1 overflow-y-auto mt-2 pb-32">
            {savedFolders.map((folder, index) => (
              <button 
                key={index} 
                className="w-full text-left p-3 bg-gray-800 rounded-lg mb-2 hover:bg-gray-700 transition-colors"
                onClick={() => handleFolderSelect(folder)}
              >
                {folder}
              </button>
            ))}
          </div> 
        </motion.div>
      )}
    </AnimatePresence>
  )
}
