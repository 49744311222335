// Import necessary dependencies
import React, { useState } from 'react';
import { ArrowLeftIcon, ShareIcon, CogIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { motion, AnimatePresence } from 'framer-motion';

// Define the ProfileScreen component
const ProfileScreen = () => {
  // State for managing selected memory and sidebar visibility
  const [selectedMemory, setSelectedMemory] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  // Mock user data
  const user = {
    name: 'Srikar Reddy',
    username: 'sr1k4r',
    profileImage: 'https://i.ibb.co/wd5D54c/IMG-5059.jpg',
    location: '📍Hyderabad, India',
    bio: 'Adventure seeker | Coffee lover | Aspiring photographer',
    pins: [
      { date: 'January 12', image: 'https://images.unsplash.com/photo-1548199973-03cce0bbc87b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=300&q=80' },
      { date: 'April 13', image: 'https://images.unsplash.com/photo-1506744038136-46273834b3fb?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=300&q=80' },
      { date: 'June 25', image: 'https://images.unsplash.com/photo-1527004013197-933c4bb611b3?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=300&q=80' },
    ],
    memories: Array.from({ length: 14 }, (_, i) => ({
      day: i + 15,
      image: `https://picsum.photos/200/200?random=${i}`,
    })),
  };

  // Animation variants for container
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  // Animation variants for individual items
  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  // Function to toggle sidebar visibility
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="bg-black text-white min-h-screen pb-32">
      {/* Header section with profile image */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="relative"
      >
        <img
          src={user.profileImage}
          alt="Profile"
          className="w-full h-72 object-cover"
        />
        {/* Header overlay with navigation buttons */}
        <div className="absolute top-0 left-0 right-0 p-4 flex justify-between items-center bg-gradient-to-b from-black/50 to-transparent">
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            <ArrowLeftIcon className="h-6 w-6 text-white" />
          </motion.button>
          <span className="text-sm font-medium">{user.username}</span>
          <div className="flex items-center space-x-2">
            <motion.button
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              onClick={toggleSidebar}
            >
              <CogIcon className="h-6 w-6 text-white" />
            </motion.button>
          </div>
        </div>
      </motion.div>

      {/* Main content section */}
      <motion.div
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        className="px-4 relative z-10 items-start justify-start flex flex-col">
        {/* User info section */}
        <motion.div variants={itemVariants} className="flex justify-between items-center mb-2 mt-4 w-full">
          <h1 className="text-3xl font-bold">{user.name}</h1>
          <div className="rounded-full px-3 py-1 text-sm flex items-center">
          <motion.button
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              <ShareIcon className="h-6 w-6 text-white" />
            </motion.button>
          </div>
        </motion.div>
        <motion.p variants={itemVariants} className="text-gray-400 mb-2 w-full text-left">{user.location}</motion.p>
        <motion.p variants={itemVariants} className="text-white mb-12 w-full text-left">{user.bio}</motion.p>

        {/* Pins section */}
        <motion.div variants={itemVariants} className="mb-8 w-full items-start justify-start flex flex-col">
          <h2 className="text-lg font-semibold mb-3">Pins</h2>
          <div className="flex space-x-3 overflow-x-auto pb-2 scrollbar-hide">
            {user.pins.map((pin, index) => (
              <motion.div
                key={index}
                whileHover={{ scale: 1.05 }}
                className="flex-shrink-0 w-28 h-40 rounded-lg overflow-hidden relative shadow-lg"
              >
                <img src={pin.image} alt={pin.date} className="w-full h-full object-cover" />
                <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black to-transparent p-2">
                  <p className="text-xs font-medium">{pin.date}</p>
                </div>
              </motion.div>
            ))}
          </div>
        </motion.div>

        {/* Memories section */}
        <motion.div variants={itemVariants} className="w-full items-start justify-start flex flex-col">
          <h2 className="text-lg font-semibold mb-2">All Memories</h2>
          <p className="text-sm text-gray-400 mb-3">Only visible to you</p>
          <div className="grid grid-cols-4 sm:grid-cols-5 md:grid-cols-6 lg:grid-cols-7 gap-2">
            {user.memories.map((memory) => (
              <motion.button
                key={memory.day}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => setSelectedMemory(memory)}
                className="aspect-square rounded-lg overflow-hidden relative"
              >
                <img src={memory.image} alt={`Day ${memory.day}`} className="w-full h-full object-cover" />
                <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-30">
                  <span className="text-xs font-semibold">{memory.day}</span>
                </div>
              </motion.button>
            ))}
          </div>
        </motion.div>
      </motion.div>

      {/* Modal for displaying selected memory */}
      {selectedMemory && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center p-4 z-50"
          onClick={() => setSelectedMemory(null)}
        >
          <motion.div
            initial={{ scale: 0.9 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0.9 }}
            className="bg-gray-900 rounded-lg overflow-hidden max-w-lg w-full"
            onClick={(e) => e.stopPropagation()}
          >
            <img src={selectedMemory.image} alt={`Day ${selectedMemory.day}`} className="w-full h-64 object-cover" />
            <div className="p-4">
              <h3 className="text-xl font-semibold mb-2">Day {selectedMemory.day}</h3>
              <p className="text-gray-400">Memory details could go here...</p>
            </div>
          </motion.div>
        </motion.div>
      )}

      {/* Sidebar for settings */}
      <AnimatePresence>
        {isSidebarOpen && (
          <>
            {/* Backdrop */}
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
              className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm z-40"
              onClick={toggleSidebar}
            />
            {/* Sidebar content */}
            <motion.div
              initial={{ x: "100%" }}
              animate={{ x: 0 }}
              exit={{ x: "100%" }}
              transition={{ type: "spring", stiffness: 300, damping: 30 }}
              className="fixed top-0 right-0 h-full w-64 bg-gray-900 shadow-lg z-50"
            >
              <div className="p-4">
                <div className="flex justify-between items-center mb-6">
                  <h2 className="text-xl font-bold">Settings</h2>
                  <motion.button
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    onClick={toggleSidebar}
                    className="text-white"
                  >
                    <XMarkIcon className="h-6 w-6" />
                  </motion.button>
                </div>
                {/* Add your settings options here */}
                <ul className="space-y-4">
                  <li>
                    <a href="#" className="text-white hover:text-gray-300">Account</a>
                  </li>
                  <li>
                    <a href="#" className="text-white hover:text-gray-300">Privacy</a>
                  </li>
                  <li>
                    <a href="#" className="text-white hover:text-gray-300">Notifications</a>
                  </li>
                  <li>
                    <a href="#" className="text-white hover:text-gray-300">Help & Support</a>
                  </li>
                </ul>
              </div>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </div>
  );
};

export default ProfileScreen;