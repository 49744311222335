'use client'

// Import necessary dependencies
import React, { useEffect, useRef, useState } from 'react'
import { ChevronLeft, ChevronDown, Star, Instagram, Phone, Globe, Map } from 'lucide-react'
import { useNavigate, useLocation } from 'react-router-dom'


export default function BusinessScreen() {
  // State to control video visibility
  const [isVideoVisible, setIsVideoVisible] = useState(true)
  // Refs for DOM elements
  const detailsRef = useRef(null)
  const videoRef = useRef(null)
  // Hooks for navigation and location
  const navigate = useNavigate()
  const location = useLocation()
  // Extract place data from location state
  const { place } = location.state || {}

  // Effect to observe intersection of details section
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Gradually change opacity based on intersection ratio
        const opacity = 1 - entry.intersectionRatio
        if (videoRef.current) {
          videoRef.current.style.opacity = opacity.toString()
        }
        setIsVideoVisible(opacity > 0.1) // Consider video invisible when opacity is very low
      },
      { 
        threshold: Array.from({length: 100}, (_, i) => i / 100), // Create thresholds for smoother transition
        rootMargin: '-50px 0px 0px 0px' // Start transition slightly before the element enters the viewport
      }
    )

    if (detailsRef.current) {
      observer.observe(detailsRef.current)
    }

    return () => {
      if (detailsRef.current) {
        observer.unobserve(detailsRef.current)
      }
    }
  }, [])

  // Effect to play video when visible
  useEffect(() => {
    if (isVideoVisible && videoRef.current) {
      videoRef.current.play().catch(error => {
        console.error("Error playing video:", error)
      })
    } else if (!isVideoVisible && videoRef.current) {
      videoRef.current.pause()
    }
  }, [isVideoVisible])

  // Sample data for upcoming scenes
  const scenes = [
    { id: 1, title: 'Live Music Night', date: 'Friday, 8 PM', image: 'https://images.unsplash.com/photo-1470225620780-dba8ba36b745?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80' },
    { id: 2, title: 'Salsa Dance Party', date: 'Saturday, 9 PM', image: 'https://images.unsplash.com/photo-1545128485-c400e7702796?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80' },
    { id: 3, title: 'Jazz Brunch', date: 'Sunday, 11 AM', image: 'https://images.unsplash.com/photo-1533090368676-1fd25485db88?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1169&q=80' },
    { id: 4, title: 'Karaoke Night', date: 'Tuesday, 7 PM', image: 'https://images.unsplash.com/photo-1516280440614-37939bbacd81?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80' },
  ]

  return (
    <div className="bg-black text-white min-h-screen pb-safe">
      {/* Header */}
      <div className="fixed top-0 left-0 right-0 bg-black bg-opacity-50 backdrop-blur-sm z-10 p-4 flex items-center">
        <button className="text-white flex flex-row items-center" onClick={() => navigate(-1)}>
          <ChevronLeft size={20} />
          <p className="text-white text-sm font-medium ml-2">Back</p>
        </button>
       
      </div>

      {/* Video background */}
      <div className="fixed inset-0 z-2">
        <video
          ref={videoRef}
          autoPlay
          loop
          muted
          playsInline
          className="object-cover w-full h-full transition-opacity duration-300"
          src={place?.video || "/placeholder.mp4"}
        >
          Your browser does not support the video tag.
        </video>
      </div>

      {/* Main content */}
      <div className="relative z-5 pt-16">
        {/* Hero section */}
        <div className="h-screen flex -m-48 items-center justify-center">
          {/* <h1 className="text-4xl font-bold text-left">{place?.name || 'Awesome Location Name'}</h1> */}

        </div>

        {/* About section */}
        <div ref={detailsRef} className="bg-gradient-to-b from-transparent to-black bg-opacity-70 backdrop-blur-lg p-8 rounded-lg shadow-xl">
          <div className="flex items-center justify-between mb-6">
            <h2 className="text-4xl font-bold text-white">{place?.name || 'Awesome Location Name'}</h2>
            <div className="flex items-center bg-yellow-400 rounded-full px-3 py-1">
              <Star className="text-black mr-1" size={20} />
              <span className="text-black font-semibold text-lg">{place?.rating || '4.5'}</span>
            </div>
          </div>
          <p className="mb-6 text-sm leading-relaxed text-gray-200 text-left">
            Welcome to <span className="font-semibold text-white">{place?.name || 'our amazing location'}</span>! We offer a unique experience with a blend of great atmosphere,
            delicious food, and unforgettable memories. Whether you're looking for a night out with friends
            or a special date spot, we've got you covered.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="bg-gray-800 p-4 rounded-lg">
              <h3 className="text-2xl font-semibold mb-3 text-purple-400">Hours</h3>
              <p className="text-gray-300">Mon-Fri: 11am - 2am</p>
              <p className="text-gray-300">Sat-Sun: 10am - 3am</p>
            </div>
            <div className="bg-gray-800 p-4 rounded-lg cursor-pointer" onClick={() => window.open(`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(place?.name || 'Business Name')}`)}>
              <h3 className="text-2xl font-semibold mb-3 text-purple-400">Location</h3>
              <p className="text-gray-300">{place?.address || '123 Awesome St, City, Country'}</p>
              <div className="mt-2 flex items-center text-blue-400">
                <Map size={16} className="mr-1" />
                <span>View on map</span>
              </div>
            </div>
          </div>
          <div className="mt-6 flex justify-center space-x-6">
            <a href="#" className="text-white hover:text-pink-500 transition-colors duration-300">
              <Instagram size={24} />
            </a>
            <a href="tel:(123) 456-7890" className="text-white hover:text-green-500 transition-colors duration-300">
              <Phone size={24} />
            </a>
            <a href="#" className="text-white hover:text-blue-500 transition-colors duration-300">
              <Globe size={24} />
            </a>
          </div>
        </div>

        {/* Upcoming scenes section */}
        <div className="bg-black bg-opacity-50 backdrop-blur-sm p-4 rounded-lg shadow-xl">
          <h2 className="text-3xl font-semibold mb-4">Upcoming Scenes</h2>
            <div className="overflow-x-auto snap-x snap-mandatory flex space-x-4 pb-4">
            {scenes.map((scene) => (
              <div key={scene.id} className="flex-shrink-0 w-64 snap-start">
                <div className="bg-gray-800 rounded-lg overflow-hidden">
                  <img src={scene.image} alt={scene.title} className="w-full h-72 object-cover" />
                  <div className="p-4">
                    <h3 className="text-xl font-semibold mb-2">{scene.title}</h3>
                    <p>{scene.date}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}